@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/wotfard/wotfard-semibold-webfont.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/wotfard/wotfard-medium-webfont.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/wotfard/wotfard-regular-webfont.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
